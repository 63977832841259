import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/all_appointment', title: 'All Appointment',  icon:'ni-calendar-grid-58 text-blue', class: '' },
    { path: '/blog', title: 'Blogs',  icon:'ni-single-copy-04 text-orange', class: '' },
    { path: '/write-blogs', title: 'Write Blog',  icon:'ni-ruler-pencil text-yellow', class: '' },
    { path: '/gallery', title: 'Gallery',  icon:'ni-album-2 text-info', class: '' },
    { path: '/User_Quary', title: 'User Quary ',  icon:'ni-single-02 ', class: '' },
    { path: '/leave', title: 'Holiday ',  icon:'ni-calendar-grid-58 text-blue', class: '' },
    { path: '/message', title: 'Message ',  icon:'ni-single-copy-04 text-blue', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  public show:boolean = false;
  public show1:boolean = false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  toggle() {
    
    if (this.show==false){
      this.show=true;
    }
    else{
      this.show=false;
    }
  }
  toggle1() {
    
    if (this.show1==false){
      this.show1=true;
    }
    else{
      this.show1=false;
    }
  }
  close()
  {
    this.isCollapsed=true;
  }
}
