import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { FetchUserDataService } from 'src/app/services/fetch-user-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-pass',
  templateUrl: './confirm-pass.component.html',
  styleUrls: ['./confirm-pass.component.css']
})
export class ConfirmPassComponent implements OnInit {

ConfirmPass:FormGroup
hide=true
hide1=true
EmaiID:any
constructor(private formbuilder:FormBuilder,
  private MatSnackBar :MatSnackBar,
  private router:Router,private fetchData: FetchUserDataService,
  private Activate:ActivatedRoute,) { 
    this.Activate.queryParams.subscribe(res=>{
      this.EmaiID = res['user']
      })
}


ngOnInit(): void {
  this.ConfirmPass = this.formbuilder.group({
    password    :  ['',Validators.required],
    confirmpass : ['', Validators.required],
  },{ validator: this.checkPasswords })
}
checkPasswords(group: FormGroup) { 
  let pass = group.get('password').value;
  let confirmPass = group.get('confirmpass').value;
return pass === confirmPass ? null : { notSame: true }
}
Message:any = [];
setPass(){
  let body = {
    Password : this.ConfirmPass.value.password,
    Confirm: this.ConfirmPass.value.confirmpass,
    EmailId:this.EmaiID
  }
  this.fetchData.confirmPassdata(body).subscribe(res=>{
    this.Message = res;
    if(this.Message.Status == true){
      this.MatSnackBar.open(this.Message.Message,"",{
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition:'center',
        panelClass:['mat-toolbar', 'mat-primary']
      });
      this.router.navigateByUrl('/sign-in')
    }else{
      this.MatSnackBar.open(this.Message.Message,"",{
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition:'center',
        panelClass:['mat-toolbar', 'mat-warn']
      });
    }
  })
}
}
