import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar'
import { FetchUserDataService } from 'src/app/services/fetch-user-data.service';
@Component({
  selector: 'app-forget-pass',
  templateUrl: './forget-pass.component.html',
  styleUrls: ['./forget-pass.component.css']
})
export class ForgetPassComponent implements OnInit {
  forgotDetails:FormGroup;
  forgotOTPDetails:FormGroup;
  Email:boolean = true;
  OTP:boolean= false
  constructor(
    private formbuilder:FormBuilder,
    private MatSnackBar :MatSnackBar,
    private router:Router,private fetchData: FetchUserDataService,
    ) { 
  }

  
  ngOnInit(): void {
    this.forgotDetails = this.formbuilder.group({
      EmailId :  ['',Validators.required],
    });
    this.forgotOTPDetails = this.formbuilder.group({
      genOTP :  ['',Validators.required],
    })
  }
  Message:any = [];
  EmailIdss:any;
  forgotPass(){
    let body = this.forgotDetails.value;
    this.fetchData.checkEmailId(JSON.stringify(body)).subscribe(res=>{
      console.log(res)
      this.Message = res;
      if(this.Message.Status == true){
        this.EmailIdss = this.Message.EmailID
        this.Email = false;
        this.OTP = true
      }
    })
  }
  Messages:any = [];
  forgotOTPSPass(){
    let body = {
      genOTP: this.Message.genOTP,
      verifyOTP:this.forgotOTPDetails.value.genOTP
    }
    this.fetchData.checkEmailOtps(JSON.stringify(body)).subscribe(res=>{
      this.Messages = res;
      if(this.Message.Status == true){
        this.MatSnackBar.open(this.Message.Message,"Dismiss!",{
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition:'center',
          panelClass:['mat-toolbar', 'mat-primary']
        });
        this.router.navigate(['/confirmpass'],{queryParams:{user:this.EmailIdss}});
      }else{
        this.MatSnackBar.open(this.Message.Message,"Dismiss!",{
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition:'center',
          panelClass:['mat-toolbar', 'mat-warn']
        });
      }
    })
  }
}
