import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SignInComponent } from './session/sign-in/sign-in.component';
import { ForgetPassComponent } from './session/forget-pass/forget-pass.component';
import { ConfirmPassComponent } from './session/confirm-pass/confirm-pass.component';

//import { RecepLayoutComponent } from './layouts/recep-layout/recep-layout.component';
//import { WriteBlogsComponent } from './pages/write-blogs/write-blogs.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
  { path: 'sign-in', component:SignInComponent},
  { path: 'forgotpass', component:ForgetPassComponent},
  { path: 'confirmpass', component:ConfirmPassComponent},
  //{ path: 'write-blogs', component:WriteBlogsComponent}
   {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    }),
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
