import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators,FormControl } from "@angular/forms";
import { Router } from '@angular/router'
import { FetchUserDataService } from "src/app/services/fetch-user-data.service";
import { MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
  hide = true;
  SignIN:FormGroup;
  Usertype:any = "receptionist-76434@45Ortho";
  Usertype2:any = "receptionist-76434@Gyno#12";
  Message:any=[];
  constructor(
    private formbuilder:FormBuilder,
    private router:Router,
    private fetchData: FetchUserDataService,
    private snackBar:MatSnackBar,
    ) { 
  }

  
  ngOnInit(): void {
    this.SignIN = this.formbuilder.group({
      EmailId :  ['',Validators.required],
      pass     : ['', Validators.required],
    })
  }

  User:any;

  sign(){
    let body = this.SignIN.value;
    var data = JSON.stringify(body);
    this.fetchData.userLogin(data).subscribe(res=>{
      this.Message = res;
      this.User = this.Message.UserType
     if( this.Message.Status == true){
       sessionStorage.setItem('CurrentUser' , JSON.stringify(this.Message.token))
      if(this.Message.UserType == "User859#373@196ReC"){
        this.router.navigate(['/AppointOrthoPatients'], {queryParams:{ user:this.Usertype2}});
        return;
      }else{
        this.router.navigateByUrl('/dashboard')
      }
      this.snackBar.open(this.Message.Message,"Dismiss!",{
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition:'center',
        panelClass:['mat-toolbar', 'mat-primary']
      });
     }else{
      this.snackBar.open(this.Message.Message,"Dismiss!",{
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition:'center',
        panelClass:['mat-toolbar', 'mat-warn']
      });
     }
    })
  }
 
}
