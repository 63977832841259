
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concat } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class FetchUserDataService {
  BaseUrl: String = "https://api.v1.caringbeyondclinic.com";
  constructor(private http: HttpClient) {
  }
  userLogin(body) {
    return this.http.post(this.BaseUrl + "/api/users/signin", body, httpOptions);
  }
  userFetchData() {
    return this.http.get(this.BaseUrl + "/api/user/fetch", httpOptions);
  }
  userSignUp(body) {
    return this.http.post(this.BaseUrl + "/api/user/create", body, httpOptions);
  }
  userProf(body) {
    return this.http.post(this.BaseUrl + "/api/user/MyProfile", body, httpOptions)
  }

  userUpdatepro(body) {
    return this.http.put(this.BaseUrl + "/api/user/update/Details", body, httpOptions)
  }
  checkEmailId(body) {
    return this.http.post(this.BaseUrl + '/api/Pass/sendOTP/forgetpass', body, httpOptions)
  }
  checkEmailOtps(body) {
    return this.http.post(this.BaseUrl + '/api/Pass/checkOTP/forgetpass', body, httpOptions)
  }
  confirmPassdata(body) {
    return this.http.put(this.BaseUrl + '/api/Pass/Forgot/Password', body, httpOptions)
  }

  getUserData() {
    return this.http.get(this.BaseUrl + '/api/user/ContactusData/all', httpOptions).pipe();
  }

  inserHolidat(body) {
    return this.http.post(this.BaseUrl + '/api/holiday/create/HoliDays/Date', body, httpOptions).pipe();
  }

  insertMessageFoot(body) {
    return this.http.post(this.BaseUrl + '/api/messages/Create/new/message', body, httpOptions).pipe();
  }
}
